<template>
  <div class="card" style="width: 18rem">
    <img
      src="/illustrations/chatting.svg"
      style="background: white; padding: 0.5rem"
      class="card-img-top"
      alt=""
    />
    <div class="card-body">
      <h5 class="card-title">Web Chat</h5>
      <p class="card-text">
        Web chat is a fully featured chat PWA that allows users to chat with
        friends online, watch YouTube videos together and so much more!
      </p>
      <div class="d-flex justify-content-between">
        <button class="btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="currentColor"
            class="bi bi-github"
            viewBox="0 0 16 16"
          >
            <path
              d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"
            />
          </svg>
          Source
        </button>
        <a class="btn" href="https://webchat.scantlebury.io" target="_blank">
          Visit site
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["s"],
};
</script>

<style lang="scss" scoped>
.card {
  transition: all 0.3s;
  box-shadow: 7px 7px 12px 0px rgba(0, 0, 0, 0.4); //, -5px -5px 20px 0px rgba(255, 255, 255, 0.2);
  background-color: rgba(255, 255, 255, 0.075);
  backdrop-filter: blur(0.5px);
  color: var(--secondary);
  border: none;
  &:hover {
    transition: all 0.3s;
    box-shadow: 7px 7px 15px 0px rgba(0, 0, 0, 0.2); //, -5px -5px 20px 0px rgba(255, 255, 255, 0.2);
    transform: scale(1.0125);
    cursor: pointer;
  }
  border-radius: 0.5rem;
  overflow: hidden;
}
.card-title {
  font-weight: bold;
  text-align: center;
  margin: 0.5rem 0rem;
}

.card-body {
  padding: 0.5rem 1rem;
  // border: solid thin var(--secondary);
  border-top: none;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}
</style>
